import { Centrifuge } from 'centrifuge';

class Centrifugo {
  constructor(url, token) {
    this.client = new Centrifuge(url, { token });
  }

  subscribe(channel, handler) {
    this.connect();
    const existingSubscription = this.client.getSubscription(channel);
    
    if (existingSubscription) {
      existingSubscription.subscribe(handler);
      return;
    }

    const newSubscription = this.client.newSubscription(channel);
    newSubscription.on('publication', (ctx) => handler(ctx));
    newSubscription.subscribe();
  }

  unsubscribe(subscription) {
    this.client.unsubscribe(subscription);
    subscription.removeAllListeners();
  }

  connect() {
    if (this.client.state !== 'connected') {
      this.client.connect();
    }
  }
}

const getMetaContent = (name) => document.querySelector(`meta[name="${name}"]`)?.getAttribute('content');

const publicUrl = getMetaContent('centrifugo-public-url');
const token = getMetaContent('centrifugo-connection-token');

const instance = publicUrl && token ? new Centrifugo(`${publicUrl}/connection/websocket`, token) : undefined;

export default instance;
